import Vue from "vue";

/*TITLE*/
document.title = "Catalunya Canalies | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Catalunya Canalies";
Vue.prototype.$subtitle = "";

/*INTRO*/
Vue.prototype.$promoter = "Catalunya Canalies";
Vue.prototype.$introSubtitle = "";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon_ac5-viena-1l-worner-193x1383--20230112120155.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "banyo1_harlem-acero-602x602-harlem-acero-333x100--20230112120121.jpg";
Vue.prototype.$image_bath2 = "banyo2_bottega-caliza-602x602-bottega-caliza-333x100-spiga-bottega-caliza-333x592--20230112120127.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon_ac5-viena-1l-worner-193x1383--20230112120155.jpg",
  },
  {
    src: "salon_ac5-viena-1l-sadnig-193x1383--20230112120101.jpg",
  },
  {
    src: "salon_ac5-viena-1l-eisenhut-193x1383--20230112120107.jpg",
  },
  {
    src: "salon_ac5-viena-1l-coglians-193x1383--20230112120113.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo1_harlem-acero-602x602-harlem-acero-333x100--20230112120121.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo2_bottega-caliza-602x602-bottega-caliza-333x100-spiga-bottega-caliza-333x592--20230112120127.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "6";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20catalunya%2035%20barcelona%2008820",
    text: "C/Catalunya 35, Barcelona, 08820",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=carretera%20marina%2019%20el%20prat%20de%20llobregat%2008820%20barcelona",
    text: "Carretera Marina, 19, El Prat de Llobregat, 08820, Barcelona",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:620206820",
    text: "620206820",
  },
  {
    icon: "mdi-email",
    link: "mailto:mfernandez@grupoferprat.es",
    text: "mfernandez@grupoferprat.es",
  },
];
